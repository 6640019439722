import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_ROUTES } from './app/app.routes';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { 

  PreloadAllModules, 
  provideRouter, 
  withDebugTracing, 
  withPreloading, 

} 
from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthInterceptor, authInterceptorProviders } from './app/_helpers/auth.interceptor';
import { provideServiceWorker } from '@angular/service-worker';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
//   // if('servieWorker' in navigator) { //} && environment.production) {
//   //   navigator.serviceWorker.register('../dist/ngsw-worker.js');
//   // }
// })
//   .catch(err => console.error(err));

  bootstrapApplication(AppComponent, {
    providers: [
      provideServiceWorker('ngsw-worker.js'),
      importProvidersFrom(HttpClientModule),
      provideRouter(APP_ROUTES, 
        withPreloading(PreloadAllModules),
     //   withDebugTracing(),
      ),
      provideHttpClient(
        withInterceptorsFromDi(),
        
      ),
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    
      provideAnimations(),
    ]
  });