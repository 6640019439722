import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormArray, UntypedFormControl,Validators } from '@angular/forms';
import { AuthService } from '../_services/auth-service.service';
//import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    standalone: false
})
export class RegisterComponent implements OnInit {
form: UntypedFormGroup;
  clicked : boolean;
  result : any;
  constructor(private authService: AuthService,
  //private snackBar: MatSnackBar
) { }

  ngOnInit(): void {
  this.clicked	= 	false;
  this.form	=	new UntypedFormGroup({
  subscription: new UntypedFormControl('',[Validators.required]),
  username: new UntypedFormControl('',[Validators.required]),	
  description: new UntypedFormControl(''),	
  email: new UntypedFormControl('',[Validators.required]),	
  password: new UntypedFormControl('',[Validators.required]),	
  });
  }

  submit(postData)
  {
  	this.authService.register(postData).subscribe(response => {
	console.log(response)
	this.result	=	JSON.parse(response);
//this.openSnackBar(this.result.result,'Sluiten');
}, error => {
//this.openSnackBar('Er is een onbekend probleem opgetreden','Sluiten');
console.log({ error })
//console.log(this.openSnackBar);
})


console.log(postData);
 this.form     =       new UntypedFormGroup({
  subscription: new UntypedFormControl(''),
  username: new UntypedFormControl(''),
  description: new UntypedFormControl(''),	
  email: new UntypedFormControl(''),
  password: new UntypedFormControl(''),
  });
	}

// 	 openSnackBar(message: string, action: string) {
// 	 let openSnackBarRef = this.snackBar.open(message, action);
// 	 openSnackBarRef.afterDismissed().subscribe(() => {
// 	 this.clicked	=	false;
//     });
//  }


  

}
