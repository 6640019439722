import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_WINKELS_URL = 'https://shoppinglistpwa.familie-van-vugt.nl/';

@Injectable({
  providedIn: 'root'
})



export class SubscribeService {

  notificationSend: boolean = false;


  constructor(private http: HttpClient
    
    
  ) {
  }
  addSubscription(data) {


    return this.http.post(API_WINKELS_URL + '/api/notification', data);
  }


  sendNotification(message: string) {
    console.log('send notification');
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          console.log('we gaan versturen! ' + result);
          //  alert('we gaan versturen');
          registration.showNotification("Bericht van de app", {
            body: message,
            icon: "/assets/icons/icon-144x144.png",
            //  vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: "vibration-sample",
          });
        });
      }
      this.notificationSend = true;
    });
  }
}
