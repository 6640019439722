import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { WinkelService }  from '../_services/winkel.service';
import { ShopDto }  from '../profile/shop-dto';

@Component({
    selector: 'app-shop-detail',
    templateUrl: './shop-detail.component.html',
    styleUrls: ['./shop-detail.component.css'],
    standalone: false
})
export class ShopDetailComponent implements OnInit {
  id: number;
  shopname: string;
 
  private sub: any;
  public shop: ShopDto;
  
  
  constructor(private route: ActivatedRoute, private router: Router, private winkelService: WinkelService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => { 
      
      this.id = +params['shopId']; // (+) converts string 'id' to a number
     
      this.winkelService.getWinkel(this.id).subscribe(
        data=>{
          this.shop = JSON.parse(data);
          this.shopname = this.shop.name;
          console.log(this.shop);
        },
        err=>{
          console.log('Er is een fout opgetreden');
        }

  );



      // In a real app: dispatch action to load the details here.
   });
  }

  deleteShop(id) :void
  {
   
    this.winkelService.deleteWinkel(id).subscribe(
      data=>{
        this.router.navigate(["/profile",{'showWinkel':true}]);
        
      },
      err=>{
        console.log('Er is iets fout gegaan');
      }
    );
  }

}
