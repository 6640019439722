import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../_services/product.service';
import { ShopDto } from '../profile/shop-dto';
import { FormBuilder, UntypedFormGroup, FormArray, UntypedFormControl } from '@angular/forms';
import { CategoryService } from '../_services/category.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  id: number;
  productname: string;
  categories:any=[];
  private sub: any;
  productCategory:number;
  public product: ShopDto;
  form: UntypedFormGroup;
  constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService,private categoryService: CategoryService) { }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(params => {

      this.id = +params['productId']; // (+) converts string 'id' to a number

      this.productService.getProduct(this.id).subscribe(
        data => {
          this.product = JSON.parse(data);
          this.productname = this.product.name;
          console.log(this.product);
          this.productCategory = this.product.category_id;
          console.log("cate"+ this.productCategory);
        },
        err => {
          console.log('Er is een fout opgetreden');
        }
      );
      this.categoryService.getCategories().subscribe(
        data => {
          this.categories = JSON.parse(data);
          console.log(this.categories);
          console.log('we zijn verder');
        },
        err => {
          console.log('Er is een fout opgetreden');
        }
      );
      // In a real app: dispatch action to load the details here.
    });
  }

  deleteProduct(id): void {

    this.productService.deleteProduct(id).subscribe(
      data => {
        this.router.navigate(["/profile", { 'showWinkel': true }]);

      },
      err => {
        console.log('Er is iets fout gegaan');
      }
    );
  }

  update(selectedId)
  {
    console.log(selectedId);
    this.productService.updateProduct(this.id,selectedId).subscribe(data =>{
      this.product = JSON.parse(data);
    })
    // this.winkelService.addWinkel(formData).subscribe(
    //   data => {
    //      this.content = JSON.parse(data);
    //      },
    //      err => {
    //              this.content = JSON.parse(err.error).message;
    //        console.log('Niet gelukt om data op te halen!');
    //      }
    //      );
    //      this.form = new UntypedFormGroup({
    //       new_value: new UntypedFormControl(''),
          
    //     });
  }

}
