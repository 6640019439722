<div style="text-align:center;margin-top:30px;margin-bottom:60px"><h1><b>Login boodschappen app</b></h1></div>

<!-- <code-input [isCodeHidden]="false"
[codeLength]="5"
(codeCompleted)="onCodeCompleted($event)">
</code-input> -->
<div class="{{statusClass}}">{{statusText}}</div>
<div style="width:100%">
    <div class="new-row">
        <div class="indicator"><div class="{{inputCode.length > 0?'dotfilled':'dot'}}"></div></div>
        <div class="indicator"><div class="{{inputCode.length > 1?'dotfilled':'dot'}}"></div></div>
        <div class="indicator"><div class="{{inputCode.length > 2?'dotfilled':'dot'}}"></div></div>
        <div class="indicator"><div class="{{inputCode.length > 3?'dotfilled':'dot'}}"></div></div>
        <div class="indicator"><div class="{{inputCode.length > 4?'dotfilled':'dot'}}"></div></div>
    </div>
    <div class="new-row">
    <div class="num-pad" (click)="add(1,$event)">1</div>
    <div class="num-pad" (click)="add(2,$event)">2</div>
    <div class="num-pad" (click)="add(3,$event)">3</div>
</div>
<div class="new-row">
    <div class="num-pad" (click)="add(4,$event)">4</div>
    <div class="num-pad" (click)="add(5,$event)">5</div>
    <div class="num-pad" (click)="add(6,$event)">6</div>
</div>
<div class="new-row">
    <div class="num-pad" (click)="add(7,$event)">7</div>
    <div class="num-pad" (click)="add(8,$event)">8</div>
    <div class="num-pad" (click)="add(9,$event)">9</div>
</div>
<div class="new-row">
    <div class="num-pad" (click)="del()">DEL</div>
    <div class="num-pad" (click)="add(0,$event)">0</div>
    <div class="num-pad" (click)="submitCode()">OK</div>
</div>
</div>
<div style="width:100%;text-align: center">

<button style="border-radius: 5px;background-color: beige;color: black;padding:5px" id="install" hidden>Klik hier om de app te installeren</button>
</div>


