<div>
    <div style="float:left;width:70px"><b><i><a [routerLink]="['../../profile',{'showProduct':true}]">Terug</a></i></b></div>
    <div style="float:left;width:65%"><h2><i>{{productname}}</i></h2></div>
    <div style="float:left;width:70px" (click)="deleteProduct(id)"><b><i>Verwijder</i></b></div>
</div>
<hr style="text-align:center;width:90%">
	

        <label>Kies categorie</label>
        <select  name="category" #category (change)="update(category.value)" >
         
            <option [value]="category.id" [selected]="category.id == product.category_id" *ngFor="let category of categories">{{category.name}}</option>
        </select>

