import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, FormArray, ReactiveFormsModule } from '@angular/forms';
import { ListService } from '../_services/list.service';
import { NgFor, NgIf, NgStyle } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-item',
    templateUrl: './item.component.html',
    imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, CdkAccordionModule, NgStyle,
        MatIconModule,
        MatAutocompleteModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,],
    styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  item: string[];
  form: UntypedFormGroup;
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: string[];
  constructor(private listService: ListService, private fb: UntypedFormBuilder) {

  }


  ngOnInit(): void {

    this.form = this.fb.group({
      winkel: new UntypedFormControl(''),
      products: new UntypedFormArray([new UntypedFormControl('')])
    });
  }

  my_handler(ev: any, ix: number, id: any): void {
    console.log('chnage' + ix, id.value);

    this.filteredOptions = this._filter(id.value);


  }

  isEmpty = (val: any) => val == null || val.trim() == '' || !(Object.keys(val) || val).length;

  numberItems() {

    if (!this.isEmpty(this.allproducts.value[this.allproducts.length - 1])) {
      //Add new field
      this.addProduct();
    }
  }
  processResult(result: any) {

    const out = [];
    if (result.length > 0) {
      result.forEach(function (val, index, array) {
        out.push(val.name);
      });
    }
    this.item = out;
  }

  showNumber() {
    if (this.item)
      return this.item.length;
    return 0;

  }

  loadList() {
    this.listService.getListOfShop(this.form.value.winkel).subscribe({
      next: (result) => this.processResult(JSON.parse(result)),

    });
    console.log(this.form.value.winkel);

  }
  subscribeToChange() {
    const stopsArray = this.form.get('products') as FormArray;



    stopsArray.valueChanges.subscribe(item => {
      // THIS WILL RETURN THE ENTIRE ARRAY SO YOU WILL NEED TO CHECK FOR THE SPECIFC ITEM YOU WANT WHEN CHANGED
      console.log(item);
      item.findIndex(x => console.log(x));
    }
    );
  }


  private _filter(value: string): string[] {
    console.log('value: ' + value);
    const filterValue = value.toLowerCase();
    console.log(filterValue);
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  submit() {
    // console.log(this.form.value);

    // console.log(this.allproducts.value); 
    // console.log(this.form.value); 
    // console.log(this.products);
    let postData = this.form.value;
    this.listService.postNewitem(postData)
      .subscribe(response => {
        //this.openSnackBar('Product(en) is/zijn toegevoegd bij ' + postData.winkel,'Sluiten');

      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
    this.form = this.fb.group({
      winkel: new UntypedFormControl(''),
      products: new UntypedFormArray([new UntypedFormControl('')]),

    });
  }

  get allproducts(): UntypedFormArray {
    return this.form.get("products") as UntypedFormArray;
  }

  products(): UntypedFormArray {
    return this.form.get("products") as UntypedFormArray
  }

  newProduct2(): UntypedFormControl {
    let control = new UntypedFormControl('naamproduct');
    console.log(this.form);
    return control;
  }

  newProduct(): UntypedFormGroup {
    let group = this.fb.group({
      naamproduct: new UntypedFormControl(''),
    });
    return group;
  }

  addProduct() {
    this.products().push(new UntypedFormControl());
  }

  removeProduct(i: number) {
    this.products().removeAt(i);
  }
}
