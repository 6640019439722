// app.routes.ts

import { Routes } from '@angular/router';
import { ListComponent } from './list/list.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { CategoryDetailComponent } from './category-detail/category-detail.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ItemComponent } from './item/item.component';
import { LoginComponent } from './login/login.component';
import { IdeaComponent } from './idea/idea.component';
import { GpsComponent } from './gps/gps.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';


export const APP_ROUTES: Routes = [
 

    // // Option 1: Lazy Loading another Routing Config
    // {
    //     path: 'flight-booking',
    //     loadChildren: () =>
    //         import('./booking/flight-booking.routes')
    //             .then(m => m.FLIGHT_BOOKING_ROUTES)
    // },
  { path: 'list',  loadComponent: () => 
    import('./list/list.component')
        .then(m => m.ListComponent)
  },
  { path: 'shop-detail/:shopId', component: ShopDetailComponent },
  { path: 'category-detail/:categoryId', component: CategoryDetailComponent },
  { path: 'product-detail/:productId', component: ProductDetailComponent },
  { path: 'item', component: ItemComponent },
  { path: 'login', component: LoginComponent },
  { path: 'idea', component: IdeaComponent},
  { path: 'gps', component: GpsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'register', component: RegisterComponent },
 
    // Option 2: Directly Lazy Loading a Standalone Component
    {
        path: 'next-flight',
        loadComponent: () => 
            import('./listnew/listnew.component')
                .then(m => m.ListnewComponent)
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];