import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListComponent } from './list/list.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemComponent } from './item/item.component';
//import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
//import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {MatButtonModule} from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { TokenStorageService } from './_services/token-storage.service';
import { RegisterComponent } from './register/register.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { IdeaComponent } from './idea/idea.component';
import { ShopDetailComponent } from './shop-detail/shop-detail.component';
import { CategoryDetailComponent } from './category-detail/category-detail.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CodeInputModule } from 'angular-code-input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GpsComponent } from './gps/gps.component';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { SubscribeService } from './_services/subscribe.service';


export const routes: Routes = [
  { path: 'list', component: ListComponent },
  { path: 'shop-detail/:shopId', component: ShopDetailComponent },
  { path: 'category-detail/:categoryId', component: CategoryDetailComponent },
  { path: 'product-detail/:productId', component: ProductDetailComponent },
  { path: 'item', component: ItemComponent },
  { path: 'login', component: LoginComponent },
  { path: 'idea', component: IdeaComponent },
  { path: 'gps', component: GpsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
  ];

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
   // ItemComponent,
  //  LoginComponent,
    ProfileComponent,
    RegisterComponent,
    IdeaComponent,
    ShopDetailComponent,
    CategoryDetailComponent,
    ProductDetailComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    CdkAccordionModule,

   
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
   
     RouterModule.forRoot(routes,{ useHash: true }),
     CodeInputModule.forRoot({
      codeLength: 5,
      isCharsCode: false,
      //code: 'abcdef'
    }),
    
  ],
  providers: [authInterceptorProviders,TokenStorageService,SubscribeService,GpsComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
