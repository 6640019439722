import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup,  UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../_services/auth-service.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { TokenStorageService } from '../_services/token-storage.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CodeInputModule } from 'angular-code-input';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone:true,
  imports:[ReactiveFormsModule,MatFormFieldModule,
    CodeInputModule,
  ],
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
form: UntypedFormGroup;
constructor(private authService: AuthService,
private tokenStorage: TokenStorageService,
private router: Router,
//private snackBar: MatSnackBar
) { }

  ngOnInit(): void {
 this.form = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });
  }

  submit(postData)
  {
  this.authService.login(postData).subscribe(response=> { 
  let data	=	JSON.parse(response);
	if(data.token)
	{
      
    console.log("token is "+data.token);
    this.tokenStorage.saveToken(data.token);
    this.tokenStorage.saveUser(data);
      this.router.navigate(['/list']);
	}
	console.log(response);
  },
  error=>{

  //this.openSnackBar('Er is wat mis gegaan','Sluiten');
  console.log('ERrror!');
  });
  	console.log(postData);
  }
  
  //    openSnackBar(message: string, action: string) {
  //        let openSnackBarRef = this.snackBar.open(message, action);
	//  openSnackBarRef.afterDismissed().subscribe(() => {
	//  	console.log('gesloten');
  //   });

  //   }
 
  // this called only if user entered full code
  onCodeCompleted(code: string) {

    this.authService.loginWithPin(code).subscribe(response=> { 
      let data	=	JSON.parse(response);
      if(data.token)
      {
          
        console.log("token is "+data.token);
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
          this.router.navigate(['/list']);
      }
      console.log(response);
      },
      error=>{
    
      //this.openSnackBar('Er is wat mis gegaan','Sluiten');
      console.log('ERrror!');
      });
    console.log('complete:'+code);
  }
 
    }
