<h2><b>Login</b></h2>
<form [formGroup]="form" (ngSubmit)="submit(form.value)">
        <div style="border:1px;width:150px;float:left">Gebruikersnaam</div>
        <div style="border:1px;width:150px;float:left"><input type=text formControlName="username" name="naamproduct"></div>
        <div style="clear:both"></div>
        <div style="border:1px;width:150px;float:left">Wachtwoord</div>
        <div style="border:1px;width:150px;float:left"><input type=password formControlName="password" name="winkel"></div>
        <div style="clear:both"></div>
 <button class="btn btn-primary" type="submit">Login</button>


</form>

<code-input [isCodeHidden]="false"
[codeLength]="5"
(codeCompleted)="onCodeCompleted($event)">
</code-input>

