import { Injectable,isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

const API_WINKELS_URL      =       (isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/product/':'https://shoppinglistpwa.familie-van-vugt.nl/product/';


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  

  constructor(private http: HttpClient) { }
  getProducts(): Observable<any> {
    return this.http.get(API_WINKELS_URL + 'overview', { responseType: 'text' });
    }
    addProduct(postData): Observable<any> {
      return this.http.post(API_WINKELS_URL + 'add-product', postData, { responseType: 'text' });
      }
      getProduct(id): Observable<any> {
        return this.http.get(API_WINKELS_URL + 'get-product?id='+id, { responseType: 'text' });
        }
        deleteProduct(id): Observable<any> {
          return this.http.get(API_WINKELS_URL + 'remove-product?id='+id, { responseType: 'text' });
          }
          updateProduct(id,category_id): Observable<any> {
            return this.http.get(API_WINKELS_URL + 'update-product?id='+id+"&category_id="+category_id, { responseType: 'text' });
            }
}
