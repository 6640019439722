import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../_services/auth-service.service';
import { Router } from '@angular/router';
//import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { TokenStorageService } from '../_services/token-storage.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CodeInputModule } from 'angular-code-input';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  imports: [
    CodeInputModule,
  ],
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //form: UntypedFormGroup;
  //form: FormGroup;
  inputCode:Array<number>;
  statusText:string = '';
  statusClass:string = '';
 
  constructor(private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.inputCode=[];
    
    // this.form = new UntypedFormGroup({
    //   username: new UntypedFormControl(''),
    //   password: new UntypedFormControl(''),
    // });
    // this.form = new FormGroup({
    //   username: new FormControl(),
    //   password: new FormControl(),
    // });



    let installPrompt = null;
    const installButton = document.querySelector("#install");

    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      installPrompt = event;
      installButton.removeAttribute("hidden");
    });

    installButton.addEventListener("click", async () => {
      if (!installPrompt) {
        return;
      }
      const result = await installPrompt.prompt();
      console.log(`Install prompt was: ${result.outcome}`);
      disableInAppInstallPrompt();
    });

   
    function disableInAppInstallPrompt() {
      installPrompt = null;
      installButton.setAttribute("hidden", "");
    }

    window.addEventListener("appinstalled", () => {
      disableInAppInstallPrompt();
    });

  }

  add(number:number,event){
    console.log(event);
      console.log('lengte: '+this.inputCode.length);
      if(this.inputCode.length < 5)
        this.inputCode.push(number);
      if(this.inputCode.length >= 5){
        this.onCodeCompleted(this.inputCode.join(''));
      }
  }

  del(){
    this.inputCode = this.inputCode.slice(0,-1);
}

submitCode(){
  console.log("de code is "+this.inputCode.join(''));
  this.onCodeCompleted(this.inputCode.join(''));
}
  submit(postData) {
    this.statusClass = 'status-notice';
    this.statusText = 'Bezig met inloggen';
    this.authService.login(postData).subscribe(response => {
      let data = JSON.parse(response);
      if (data.token) {

        console.log("token is " + data.token);
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
        this.router.navigate(['/list']);
      }
      console.log(response);
      this.inputCode = [];
      this.statusClass = 'status-error';
    this.statusText = 'foutje';
    },
      error => {
        console.log('ERrror!');
      });
    console.log(postData);
    
    this.inputCode = [];
  }



  onCodeCompleted(code: string) {
    this.statusClass = 'status-notice';
    this.statusText = 'Bezig met inloggen';
    this.authService.loginWithPin(code).subscribe(response => {
      let data = JSON.parse(response);
      if (data.token) {

        console.log("token is " + data.token);
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
        this.router.navigate(['/list']);
      }
      this.statusClass = 'status-error';      
      this.statusText = data.result;
      console.log(response);
      
    },
      error => {

        //this.openSnackBar('Er is wat mis gegaan','Sluiten');
        console.log('ERrror!');
      });
    console.log('complete:' + code);
    this.inputCode = [];
  }

}
