import { Component, ViewChild, ElementRef, OnInit,Inject } from '@angular/core';
import { ListService } from '../_services/list.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ListDto } from './list-dto';
import { AppComponent } from '../app.component';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  form: UntypedFormGroup;
  website: UntypedFormArray;
  content:  ListDto[] ;
  shop_arr: Array<any>;
  shop_selected:string;
  product: string;
  name: string;
  submitForm:boolean=true;

  @ViewChild('myitems') items: ElementRef;
  constructor(private listService: ListService, private fb: UntypedFormBuilder, private appcomponent: AppComponent,public dialog: MatDialog) {
    this.shop_arr = new Array(1, 2);
  }
  myitems: string;
  ngOnInit(): void {
   
    this.listService.getList().subscribe(
      data => {
        const result = JSON.parse(data);
        if(result.result) {
          this.appcomponent.Signout();
        }
        this.content = result;
       
        this.addItem();
        console.log('de lijst is '+this.shop_arr);
        this.shop_selected  = this.shop_arr[0].name;
      },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log('Niet gelukt om data op te halen!');
      }
    );
    this.form = this.fb.group({
      website: this.fb.array([])
    });
    
  }

  openDialog(id:number,product:string): void {
    this.product = product;
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {name: this.name, product: this.product},
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed '+result);
      this.listService.updateItem(id,result).subscribe({
        complete: () => this.updateList()
      });
      
  //    this.product = result;
    });
  }

  updateList(){
    console.log('shop is '+ this.shop_selected);
    this.listService.getList().subscribe(
      data => {
        const result = JSON.parse(data);
        if(result.result) {
          this.appcomponent.Signout();
        }
        this.content = result;
        this.addItem();
        console.log('de lijst is '+this.shop_arr);
      });
  }

  addProduct(e){
    console.log('we gaan iets toevoegen');
    e.preventDefault();
  }
  addItem() {
    let names = [];
    let sorted_items = [];
    this.shop_arr.push(10);
    console.log('we gaan het doen met '+this.content.length);
    console.log(this.content);
   
    this.content.forEach(function (value, key) {
      console.log(value);
      if(value.result)
      {
        console.log('errortje'+value.result);
      }
      if (names.indexOf(value.shop) == -1) {
        names.push(value.shop);
        //	sorted_items.push(value.shop);
        sorted_items.push({ name: value.shop, items: [] });
      }
      sorted_items[names.indexOf(value.shop)].items.push({ id: value.id, product: value.name });
    });
    this.shop_arr = sorted_items;
    console.log(this.shop_arr);
  }

  onItemOpened(item)
  {
    this.shop_selected  = item.name;
  }
  onCheckboxChange(e) {
    this.website = this.form.get('website') as UntypedFormArray;

    if (e.target.checked) {
      this.website.push(new UntypedFormControl(e.target.value));

    } else {

      const index = this.website.controls.findIndex(x => x.value === e.target.value);
      this.website.removeAt(index);
    }
  }
  removeItem(i) {
    console.log("veriwjderne van " + i);
    this.listService.removeItem(i).subscribe(
      data => {
        this.content = JSON.parse(data);
        this.form = this.fb.group({
          website: this.fb.array([])
        });

      },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log('Erorr!');
      }
    );
  }
  removeItems() {
    console.log('Verwijderen items');
    this.submitForm=false;
    console.log(this.form.value);
    this.listService.removeItems(this.form.value).subscribe(
      data => {
        this.content = JSON.parse(data);
        console.log(this.content);
        console.log('nu opgheaald');
        this.addItem();
        this.form = this.fb.group({
          website: this.fb.array([])
        });

      },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log('Erorr!');
      }
    );
  }
  submit() {
    if(!this.submitForm){
      this.submitForm=true;
      return ;
    }
    console.log('submit');
    console.log(this.form.value);
    this.listService.postData(this.form.value).subscribe(
      data => {
        this.content = JSON.parse(data);
        this.addItem();
        this.form = this.fb.group({
          website: this.fb.array([])
        });

      },
      err => {
        this.content = JSON.parse(err.error).message;
        console.log('Erorr!');
      }
    );
  }
}

export interface DialogData {
  product: string;
  name: string;
}  

@Component({
  selector: 'update-item',
  templateUrl: 'update-item.html',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

