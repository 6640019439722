import { Injectable,isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

const API_LIST_URL	=	(isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/list/':'https://shoppinglistpwa.familie-van-vugt.nl/list/';
const API_ITEM_URL	=	(isDevMode())?'https://test-shoppinglistpwa.familie-van-vugt.nl/item/':'https://shoppinglistpwa.familie-van-vugt.nl/item/';

@Injectable({
  providedIn: 'root'
})
export class ListService {
constructor(private http: HttpClient) { }
getList(): Observable<any> {
    return this.http.get(API_LIST_URL + 'overview', { responseType: 'text' });
    }
    getListOfShop(shop:string): Observable<any> {
      return this.http.get(API_LIST_URL + 'listofshop?shop='+shop, { responseType: 'text' });
      }
  postData(input : any):  Observable<any> {
	  return this.http.post(API_LIST_URL + 'postdata', input , { responseType: 'text'});
	  }
  postNewitem(input : any):  Observable<any> {
	  return this.http.post(API_ITEM_URL + 'newitem', input , { responseType: 'text'});
    }
  removeItem(input : any):  Observable<any> {
	  return this.http.post(API_ITEM_URL + 'removeitem', input , { responseType: 'text'});
    }
    removeItems(input : any):  Observable<any> {

      return this.http.post(API_ITEM_URL + 'removeitems', JSON.stringify(input) , { responseType: 'text'});
      }
  updateItem(id:number,input : any):  Observable<any> {
    
      return this.http.post(API_ITEM_URL + 'updateitem?id='+id, input , { responseType: 'text'});
      }



}
